import { Col, Modal, Row } from 'antd'
import React, { ReactNode } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export default function HistoryLayout(props: {
  title?: string | ReactNode
  children?: ReactNode
}) {
  const navigate = useNavigate()

  const onClose = () => {
    try {
      navigate(-1)
    } catch {
      navigate(`/gestion-de-stock/stock`)
    }
  }

  return (
    <Modal
      prefixCls="modal-new-stock"
      centered
      open={true}
      width={'90%'}
      footer={null}
      closeIcon={null}
    >
      <div className="flex flex-col h-full">
        <Row
          className="header bd-bot-page-title pb-1"
          justify={'space-between'}
          align={'middle'}
        >
          <Col>
            <span>{props.title}</span>
          </Col>
          <Col>
            <i className="icon-close-modal" onClick={onClose}></i>
          </Col>
        </Row>

        <div className="flex-grow overflow-hidden">{props.children}</div>
      </div>
    </Modal>
  )
}
