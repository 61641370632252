import { ReferenceForHistory } from '../../../models/History'

import dayjs from 'dayjs'
import { Unit } from '../../../enum/Unit'
import { randomString } from '../../../utils/random'
import { GroupType, ReferenceEventType } from '../enum/enum'
import {
  getElementFromObjectSearchType,
  getElementFromObjectType,
} from '../func'
import { LineData } from '../table/model'

export function getGroup(eventType: ReferenceEventType): GroupType {
  switch (eventType) {
    case ReferenceEventType.R1:
      return GroupType.Creation
    case ReferenceEventType.R2:
      return GroupType.Suppression
    case ReferenceEventType.R3:
    case ReferenceEventType.R4:
    case ReferenceEventType.R5:
    case ReferenceEventType.R6:
    case ReferenceEventType.R7:
    case ReferenceEventType.R8:
    case ReferenceEventType.R34568:
      return GroupType.Edition
  }

  return GroupType.NULL
}

const splitString = '_-_'

function getValues(values: string) {
  if (values.includes(splitString)) {
    // const newValues = values.substring(0, values.length - 3);
    const valueList = values.split(splitString)
    return valueList
  }

  return []
}

function mapFieldToLabel(field: string): string {
  if (field.includes('[') && field.includes(']')) {
    if (field.includes('Conditionnement') && field.includes('NoCodebarre'))
      return 'Code barre'
    if (field.includes('Conditionnement') && field.includes('TypeDeCodebarre'))
      return 'Type CB'

    if (field.includes('Conditionnement') && field.includes('Support'))
      return 'Support'

    if (field.includes('Conditionnement') && field.includes('Longueur'))
      return 'Longueur'
    if (field.includes('Conditionnement') && field.includes('Largeur'))
      return 'Largeur'
    if (field.includes('Conditionnement') && field.includes('Hauteur'))
      return 'Hauteur'
    if (field.includes('Conditionnement') && field.includes('Nom')) return 'Nom'
    if (field.includes('Conditionnement') && field.includes('PoidsNet'))
      return 'Poids Net'
    if (field.includes('Conditionnement') && field.includes('PoidsBrut'))
      return 'Poids Brut'
    if (field.includes('Conditionnement') && field.includes('PoidsAdd'))
      return 'Poids Add'
    if (field.includes('Conditionnement') && field.includes('Qte'))
      return 'Qte / contenant'
    if (field.includes('Conditionnement') && field.includes('Variante'))
      return 'Variante'
    if (field.includes('Conditionnement') && field.includes('Couche'))
      return 'Couches'

    if (
      field.includes('AttProduction') &&
      field.includes('RefFournisseurNumber')
    )
      return 'Réf fournisseur'
    if (field.includes('AttProduction') && field.includes('Libelle'))
      return 'Libellé'
    if (field.includes('AttProduction') && field.includes('Lot')) return 'Lot'
    if (field.includes('AttProduction') && field.includes('Conditionement'))
      return 'Cdn'
    if (field.includes('AttProduction') && field.includes('Sscc')) return 'SSCC'
  }

  switch (field) {
    case 'LibelleCourt':
      return 'Libellé court'

    case 'LibelleLong':
      return 'Libellé long'

    case 'SousFamille':
      return 'Sous-Famille'

    case 'Marque':
      return 'Marque'

    case 'Origine':
      return 'Origine'

    case 'Famille':
      return 'Famille'

    case 'AttFournisseur.CodeNom':
      return 'Code & Nom Fournisseur'

    case 'Photos':
      return 'Photo(s)'

    case 'Variante':
      return 'Variante'

    case 'DLC':
      return 'DLC'

    case 'DLUO':
      return 'DLUO'

    case 'DLV':
      return 'DLV'

    case 'Gestion':
      return 'Gestion de Lot'

    case 'BlocageEntree':
      return 'Blocage entrée'

    case 'BlocageSortie':
      return 'Blocage sortie'

    case 'DLCValue':
      return 'Nbr jours (DLC)'
    case 'DLVValue':
      return 'Nbr jours (DLV)'
    case 'DLUOValue':
      return 'Nbr jours (DLUO)'
    case 'RefLivraison.Comment':
      return 'Livraison'
    case 'RefCharement.Comment':
      return 'Chargement'
    case 'RefReception.Comment':
      return 'Réception'
  }

  return ''
}

export function mapTypeToEvent(
  refHistory: ReferenceForHistory
): { event: string; value: string }[] {
  const {
    event_type = ReferenceEventType.TYPE_NULL,
    changes = [],
    object_number = '',
    description = '',
  } = refHistory

  switch (event_type) {
    case ReferenceEventType.R1:
      return [
        {
          event: `Création Référence`,
          value: object_number,
        },
      ]
    case ReferenceEventType.R2:
      return [
        {
          event: `Suppression Référence`,
          value: object_number,
        },
      ]
    case ReferenceEventType.R34568: {
      let final: { event: string; value: string }[] = []
      changes.forEach((change) => {
        const changeEventType = change.event_type
        const { field = '', value = '' } = change
        let { old_value, new_value } = change

        const label = mapFieldToLabel(field)

        if (
          field === 'Livraison' ||
          field === 'Commande' ||
          (field.includes('AttProduction') && field.includes('DLUO'))
        ) {
          if (old_value !== '0' && old_value)
            old_value = dayjs(Number(old_value) * 1000).format('DD/MM/YYYY')
          if (new_value !== '0' && new_value)
            new_value = dayjs(Number(new_value) * 1000).format('DD/MM/YYYY')
        }

        if (['DLVValue', 'DLUOValue', 'DLCValue'].includes(field)) {
          if (old_value === '0') old_value = ''
          if (new_value === '0') new_value = ''
        }

        if (changeEventType === ReferenceEventType.R3) {
          if (field === 'Photo')
            final.push({ event: 'Edition photo', value: '' })
          else if (!old_value && new_value) {
            final.push({ event: `Renseignement ${label}`, value: new_value })
          } else if (
            [
              'BlocageEntree',
              'DLC',
              'DLV',
              'DLUO',
              'BlocageSortie',
              'BlocageEntree',
              'Gestion',
              'Variante',
            ].includes(field)
          ) {
            if (new_value === 'true') {
              final.push({
                event: `Edition l'interrupteur ${label}`,
                value: 'Activé',
              })
            } else
              final.push({
                event: `Edition l'interrupteur ${label}`,
                value: 'Désactivé',
              })
          } else
            final.push({
              event: `Edition ${label}`,
              value: `${getSymbol(old_value)} \u279E ${getSymbol(new_value)}`,
            })
        }
        if (changeEventType === ReferenceEventType.R4) {
          const niv = getNiv(value)

          if (field.includes('Conditionnement[')) {
            if (field.includes('AttEnable')) {
              if (new_value === 'true') {
                final.push({
                  event: `Edition l'interrupteur Attendu`,
                  value: `Activé (niv.${niv})`,
                })
              } else
                final.push({
                  event: `Edition l'interrupteur Attendu`,
                  value: `Désactivé (niv.${niv})`,
                })
            } else if (field.includes('Couche')) {
              if (old_value === '0') {
                final.push({
                  event: `Renseignement ${label} (niv.${niv})`,
                  value: `${new_value}`,
                })
              } else if (new_value === '0') {
                final.push({
                  event: `Edition ${label} (niv.${niv})`,
                  value: `${old_value} -> Non renseigé`,
                })
              } else {
                final.push({
                  event: `Edition ${label} (niv.${niv})`,
                  value: `${old_value} -> ${new_value}`,
                })
              }
            } else {
              final.push({
                event: `Edition ${label} (niv.${niv})`,
                value: `${getSymbol(old_value)} ➞ ${getSymbol(new_value)}`,
              })
            }
          } else if (field.includes('RefFournissers[')) {
            const [nom, refFour] = getFournisserUnique(value)
            let newRef = ''
            let newRefFour = ''
            if (field.includes('ReferenceFournisser')) {
              newRef = nom
              newRefFour = new_value || ''
            } else if (field.includes('FournisserId')) {
              newRef = new_value || ''
              newRefFour = refFour
            }
            final.push({
              event: `Edition Fournisseur, Référence Fournisseur`,
              value: `(${getSymbol(nom)}, ${getSymbol(refFour)}) ➞ (${getSymbol(
                newRef
              )}, ${getSymbol(newRefFour)})`,
            })
          }
        }
        if (changeEventType === ReferenceEventType.R5) {
          if (field === 'Conditionnement') {
            const niv = getNiv(value)
            final.push({ event: `Suppression (niv.${niv})`, value: '' })
          } else if (field === 'Photo') {
            final.push({ event: 'Suppression Photo', value: '' })
          } else if (field === 'RefFournissers') {
            const [nom] = getFournisserUnique(value)
            final.push({ event: `Suppression Fournisseur (${nom})`, value: '' })
          }
        }

        if (changeEventType === ReferenceEventType.R6) {
          if (field === 'Photo') {
            final.push({ event: 'Ajout Photo', value: '' })
          } else if (field === 'Conditionnement') {
            const [niv] = getNiv(value)
            final.push({ event: `Ajout Cdn (niv.${niv})`, value: '' })
          } else if (field === 'RefFournissers') {
            const [nom, refFour] = getFournisserUnique(value)
            final.push({
              event: `Ajout Fournisseur (${getSymbol(
                nom
              )}); Référence Fournisseur (${getSymbol(refFour)})`,
              value: '',
            })
          }
        }
      })
      return final
    }
    case ReferenceEventType.R7: {
      const [nom, refNom] = getFournisserUnique(description)
      if (refNom) {
        return [
          {
            event: `Edition association Fournisseur`,
            value: `${nom} (Ref fournisseur ${refNom})`,
          },
        ]
      } else {
        return [{ event: `Edition : association Fournisseur`, value: nom }]
      }
    }
  }
  // case AttenduEventType.A8:
  //   return [`Envoi en mission Attendu (${object_number})`]
  // case AttenduEventType.A9_1:
  //   return [`Entrée sur Attendu (${object_number}) à quai`]
  // case AttenduEventType.A9_2:
  //   return [`Entrée sur Attendu (${object_number}) au stock`]

  // case AttenduEventType.A10:
  //   return [`Entrée sur Attendu (${object_number}) au stock`]

  return [{ event: '', value: '' }]
}

export function buildLines(refHistories: ReferenceForHistory[]): LineData[] {
  const result: LineData[] = []

  refHistories.forEach((refHistory, idx) => {
    const eventWithValues = mapTypeToEvent(refHistory)
    const {
      created_at = 0,
      user_name = '',
      chrono = '',
      event_type = ReferenceEventType.TYPE_NULL,
      object_type = '',
      object_number = '',
      object_search_type = '',
    } = refHistory
    const group = getGroup(event_type)
    const mainLine: LineData = {
      id: randomString(16),
      data: [
        { name: 'chrono', value: chrono },
        { name: 'date', value: String(created_at * 1000) },
        { name: 'heure', value: String(created_at * 1000) },
        { name: 'utilisateur', value: user_name },
        { name: 'object_type', value: getElementFromObjectType(object_type) },
        { name: 'object_number_or_sscc', value: object_number },
        {
          name: 'object_search_type',
          value: getElementFromObjectSearchType(object_search_type || ''),
        },
        { name: 'values', value: '' },
      ],
    }
    result.push(mainLine)

    if (eventWithValues.length === 1) {
      let evenementValue = `${group}: ${eventWithValues[0].event}`
      if (evenementValue.includes('Edition: Edition')) {
        const evenementValueSplit = evenementValue.split('Edition: Edition')
        evenementValue = 'Edition: ' + evenementValueSplit[1]
      }
      if (evenementValue.includes('Création: Création')) {
        const evenementValueSplit = evenementValue.split('Création: Création')
        evenementValue = 'Création: ' + evenementValueSplit[1]
      }
      if (evenementValue.includes('Suppression: Suppression')) {
        const evenementValueSplit = evenementValue.split(
          'Suppression: Suppression'
        )
        evenementValue = 'Suppression: ' + evenementValueSplit[1]
      }
      mainLine.data.push({ name: 'evenement', value: evenementValue })
      mainLine.data.push({
        name: 'value',
        value: `${eventWithValues[0].value}`,
      })
    } else {
      mainLine.data.push({ name: 'evenement', value: `${group}` })
      mainLine.childrenLineId = []

      eventWithValues.forEach((eventWithValue, idx) => {
        const childId = randomString(16)
        if (mainLine.childrenLineId) mainLine.childrenLineId.push(childId)

        const childLine: LineData = {
          id: childId,
          parentLineId: mainLine.id,
          data: [
            { name: 'chrono', value: chrono },
            { name: 'date', value: String(created_at * 1000) },
            { name: 'heure', value: String(created_at * 1000) },
            { name: 'utilisateur', value: user_name },
            { name: 'evenement', value: eventWithValue.event },
            { name: 'value', value: eventWithValue.value },
            {
              name: 'object_type',
              value: getElementFromObjectType(object_type),
            },
            { name: 'object_number_or_sscc', value: object_number },
            {
              name: 'object_search_type',
              value: getElementFromObjectSearchType(object_search_type || ''),
            },
          ],
        }
        result.push(childLine)
      })
    }
  })

  return result
}

function getSymbol(value: string | undefined, unit?: Unit) {
  if (value) return `${value}${unit || ''}`
  return 'Non renseigné'
}

function displayValue(name: string, value: any) {
  if (!value) return

  switch (name) {
    case 'Lot':
    case 'Cdn':
    case 'Qte':
      return `${name}(${value})`
    case 'SSCC':
      return `${name}(${value})`
    case 'DLUO':
      if (value === '0') return ''
      return `${name}(${dayjs(Number(value) * 1000).format('DD/MM/YYYY')})`
  }

  return ''
}

function getNiv(value: string) {
  if (!value) return ''
  if (!value.includes('_-_')) return ''
  const [nix] = value.split('_-_')[0]
  return nix
}

function getFournisserUnique(value: string) {
  if (!value.includes('_-_')) return ['', '']
  const [nom, refFour] = value.split('_-_')
  return [nom, refFour]
}

// export function getRefNomFromHistory(historyResponse: ReferenceHistoriesResponse | undefined){
//     if(!historyResponse) return '';
//     if(!historyResponse.data.entry) return '';
//     for(let i = 0 ; i < historyResponse?.data?.entry?.length || 0; i++){
//         const history = historyResponse.data.entry[i];
//         if(history.old) return history.old.nom || '';
//         if(history.new) return history.new.nom || '';
//     }

//     return '';
// }
